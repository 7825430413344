<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <SliderSection v-if="moduleRole('slider')" :modulePage="modulePage"/>

          <b-overlay :show="loadingShow" v-if="moduleRole('our_services')">
            <b-card>
              <b-card-header>
                <div class="d-flex justify-content-between">
                  <b-card-title title-tag="h5">Our Services</b-card-title>
                  <div class="text-right">
                    <b-button :to="{name: 'BoServices'}" class="btn-rounded" variant="primary">
                      Services List
                      <i class="fa fa-arrow-circle-right ml-2" />
                    </b-button>
                  </div>
                </div>
              </b-card-header>
              <b-card-body>
                <div class="d-flex justify-content-between">
                  <b-form-checkbox v-model="showService" @change="doChangeShowContent('show-service-home', $event)" value="Y" unchecked-value="N" name="showService">Show Section?</b-form-checkbox>
                    <button @click="openModalTitle(service, 'home-service-title')" type="button" class="btn btn-rounded ml-auto btn-outline-info" variant="secondary" size="sm" pill>
                      Update Content <i class="fas fa-pencil-alt ml-2"></i>
                    </button>
                </div>
                <div class="wrap_title_desc mt-2">
                  <h4>{{ service.asc_title_id }}</h4>
                  <p>{{ service.asc_sub_title_id }}</p>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>

          <AboutL9 v-if="moduleRole('about_l9')" @doChangeShowContent="doChangeShowContent(...arguments)"></AboutL9>
          <IdeasSection v-if="moduleRole('ideas')" @doChangeShowContent="doChangeShowContent(...arguments)"></IdeasSection>
          <AchievementSection v-if="moduleRole('achievement')" @doChangeShowContent="doChangeShowContent(...arguments)"></AchievementSection>
          
          <b-overlay :show="loadingShow" v-if="moduleRole('our_work')">
            <b-card>
              <b-card-header>
                <div class="d-flex justify-content-between">
                  <b-card-title title-tag="h5">Recent Work</b-card-title>
                  <div class="text-right">
                    <b-button :to="{name: 'BoOurWorks'}" class="btn-rounded" variant="primary">
                      Our Work List
                      <i class="fa fa-arrow-circle-right ml-2" />
                    </b-button>
                  </div>
                </div>

              </b-card-header>
              <b-card-body>
                <div class="d-flex justify-content-between">
                  <b-form-checkbox v-model="showOurWork" @change="doChangeShowContent('show-our-work-home', $event)" value="Y" unchecked-value="N" name="showOurWork">Show Section?</b-form-checkbox>
                  <button @click="openModalTitle(recentWork, 'home-recent-work-title')" type="button" class="btn btn-rounded ml-auto btn-outline-info" variant="secondary" size="sm" pill>
                    Update Content <i class="fas fa-pencil-alt ml-2"></i>
                  </button>
                </div>
                <div class="wrap_title_desc mt-2">
                  <h4>{{ recentWork.asc_title_id }}</h4>
                  <p>{{ recentWork.asc_sub_title_id }}</p>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>

          <b-overlay :show="loadingShow" v-if="moduleRole('journal')">
            <b-card>
              <b-card-header>
                <div class="d-flex justify-content-between">
                  <b-card-title title-tag="h5">Latest Journal</b-card-title>
                  <div class="text-right">
                    <b-button :to="{name: 'BoJournal'}" class="btn-rounded" variant="primary">
                      Journal List
                      <i class="fa fa-arrow-circle-right ml-2" />
                    </b-button>
                  </div>
                </div>
              </b-card-header>
              <b-card-body>
                <div class="d-flex justify-content-between">
                  <b-form-checkbox v-model="showJournal" @change="doChangeShowContent('show-journal-home', $event)" value="Y" unchecked-value="N" name="showJournal">Show Section?</b-form-checkbox>
                  <button @click="openModalTitle(journal, 'home-journal-title')" type="button" class="btn btn-rounded ml-auto btn-outline-info" variant="secondary" size="sm" pill>
                    Update Content <i class="fas fa-pencil-alt ml-2"></i>
                  </button>
                </div>
                <div class="wrap_title_desc mt-2">
                  <h4>{{ journal.asc_title_id }}</h4>
                  <p>{{ journal.asc_sub_title_id }}</p>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>

          <b-overlay :show="loadingShow" v-if="moduleRole('industry')">
            <b-card>
              <b-card-header>
                <div class="d-flex justify-content-between">
                  <b-card-title title-tag="h5">Industry</b-card-title>
                  <div class="text-right">
                    <b-button :to="{name: 'BoIndustries'}" class="btn-rounded" variant="primary">
                      Industry List
                      <i class="fa fa-arrow-circle-right ml-2" />
                    </b-button>
                  </div>
                </div>
              </b-card-header>
              <b-card-body>
                <div class="d-flex justify-content-between">
                  <b-form-checkbox v-model="showIndustry" @change="doChangeShowContent('show-industry-home', $event)" value="Y" unchecked-value="N" name="showIndustry">Show Section?</b-form-checkbox>
                  <button @click="openModalTitle(industry, 'home-industry-title')" type="button" class="btn btn-rounded ml-auto btn-outline-info" variant="secondary" size="sm" pill>
                    Update Content <i class="fas fa-pencil-alt ml-2"></i>
                  </button>
                </div>
                <div class="wrap_title_desc mt-2">
                  <h4>{{ industry.asc_title_id }}</h4>
                  <p>{{ industry.asc_sub_title_id }}</p>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>

          <b-modal id="modal_title" title="Title" size="lg">
            <validation-observer ref="VFormTitle" v-slot="{handleSubmit}">
              <b-form @submit.prevent="handleSubmit(submitTitle)">
                <b-form-group>
                  <template #label>Title (ID) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalTitle.asc_title_id"></b-form-input>
                  <VValidate name="Title (ID)" v-model="dataModalTitle.asc_title_id" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Sub Title (ID)</template>
                  <b-form-textarea v-model="dataModalTitle.asc_sub_title_id"></b-form-textarea>
                  <VValidate name="Sub Title (ID)" v-model="dataModalTitle.asc_sub_title_id" rules="min:3" />
                </b-form-group>
                <b-form-group>
                  <template #label>Title (EN) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalTitle.asc_title_en"></b-form-input>
                  <VValidate name="Title (EN)" v-model="dataModalTitle.asc_title_en" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Sub Title (EN)</template>
                  <b-form-textarea v-model="dataModalTitle.asc_sub_title_en"></b-form-textarea>
                  <VValidate name="Sub Title (EN)" v-model="dataModalTitle.asc_sub_title_en" rules="min:3" />
                </b-form-group>
              </b-form>
            </validation-observer>
            <template #modal-footer>
              <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_title')">Cancel</b-button>
              <b-button type="button" variant="primary" @click="submitTitle">Submit</b-button>
            </template>
          </b-modal>

        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>

<script>
import GlobalVue from "@/libs/Global.vue"
import Gen from "@/libs/Gen"
import SEOSection from "@/components/SEOSection"
import SliderSection from "./SliderSection.vue"
import AboutL9 from "./AboutL9.vue"
import IdeasSection from "./IdeasSection.vue"
import AchievementSection from "./AchievementSection.vue"

export default {
  name: "BoHome",
  extends: GlobalVue,
  components: {
    SliderSection,
    SEOSection,
    AboutL9,
    IdeasSection,
    AchievementSection
  },
  data() {
    return {
      loadingShow: false,
      showService: 'N',
      showOurWork: 'N',
      showJournal: 'N',
      showIndustry: 'N',
      service: {},
      recentWork: {},
      journal: {},
      industry: {},
      dataModalTitle: {},
    }
  },
  mounted(){
    this.getShowContent()
    this.getTitle()
  },
  methods: {
    doDelete(v, params = {}, callback) {
      let id = v[params.idKey]
      let obj = params.obj || this.$route.meta.objName
      this.$swal({
        title: "Delete this " + obj + "?" || "Data" + "?",
        text: "You won't be able to restore this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.swalLoading()

          Gen.apiRest(
            "/do/" + this.modulePage,
            { data: { type: params.type, id: id } },
            "POST"
          )
            .then((resp = {}) => {
              this.$swal({
                title: resp.data.message || "Deleted Successfully!",
                icon: "success",
              }).then(() => {
                callback()
              })
            })
            .catch(err => {
              if (err) {
                err.statusType = err.status
                err.status = "error"
                err.title = err.response.data.title
                err.message = err.response.data.message
                err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
        }
      })
    },
    getShowContent(){

      Gen.apiRest("/get/BoHome/show-content")
      .then(res => {
        this.$set(this, 'showService', res.data.showService)
        this.$set(this, 'showOurWork', res.data.showOurWork)
        this.$set(this, 'showJournal', res.data.showJournal)
        this.$set(this, 'showIndustry', res.data.showIndustry)
      })
    },
    doChangeShowContent(slug, value){
      Gen.apiRest("/do/BoHome", {
        data: {
          type: 'submit-show-content',
          value: value,
          slug: slug
        }
      }).then(res => {
        this.$swal({
          icon: 'success',
          title: res.data.message
        })
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    },
    openModalTitle(value, slug){
      this.$set(this, 'dataModalTitle', _.clone({
        asc_slug: slug,
        asc_title_id: value.asc_title_id, 
        asc_title_en: value.asc_title_en,
        asc_sub_title_id: value.asc_sub_title_id, 
        asc_sub_title_en: value.asc_sub_title_en
      }))
      this.$bvModal.show('modal_title')
    },
    getTitle(){
      Gen.apiRest("/get/BoHome/title")
      .then(res => {
        this.$set(this, 'service', res.data.service)
        this.$set(this, 'recentWork', res.data.recentWork)
        this.$set(this, 'journal', res.data.journal)
        this.$set(this, 'industry', res.data.industry)
      })
    },
    submitTitle(){
      this.$refs.VFormTitle.validate().then(result => {
        if(!result) return

        this.swalLoading()

        Gen.apiRest("/do/BoHome", {
          data: { type: "submit-title", ...this.dataModalTitle },
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$bvModal.hide('modal_title')
          this.getTitle()
        })
      })
    }
  },
  watch: {
    $route() {
      this.apiGet()
    },
  },
}
</script>
