<template>
  <b-card>
    <b-card-header>
      <b-card-title title-tag="h5">Let our team bring your ideas to life!</b-card-title>
    </b-card-header>
    <b-card-body>
      <div class="d-flex align-items-center mb-2">
        <b-form-checkbox class v-model="showIdeas" @change="$emit('doChangeShowContent', 'show-ideas-home', $event)" value="Y" unchecked-value="N" name="showAbout">Show Section?</b-form-checkbox>
        <button @click="openModalTitle" type="button" class="btn btn-rounded ml-auto btn-outline-info" variant="secondary" size="sm" pill>
          Update Content <i class="fas fa-pencil-alt ml-2"></i>
        </button>
      </div>
      <div class="wrap_title_desc">
        <h4>{{ideas.asc_title_id}}</h4>
        <p>{{ideas.asc_sub_title_id}}</p>
      </div>
      <b-table-simple hover caption-top responsive>
        <b-thead head-variant="green">
          <b-tr>
            <b-th>#</b-th>
            <b-th width="200px">Title</b-th>
            <b-th>Image</b-th>
            <b-th>Status</b-th>
            <b-th>Action</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(value, index) in ideas.asc_data" :key="index">
            <b-td>{{ index + 1 }}</b-td>
            <b-td>{{ value.title_id }}</b-td>
            <b-td><img :src="parent.uploader(value.image)"></b-td>
            <b-td>
              <span v-if="value.is_active == 'Y'" class="badge badge-success">Active</span>
              <span v-else class="badge badge-danger">Inactive</span>
            </b-td>
            <b-td>
              <button @click="openModalIdeas(value)" class="btn btn-icon btn-secondary rounded-pill" target="_self"><i
              class="fas fa-pencil-alt"></i></button>
              <button @click="doDelete(value.id)" type="button" class="btn btn-icon btn-danger rounded-pill"><i
              class="far fa-trash-alt"></i></button>
            </b-td>
          </b-tr>
        </b-tbody>
        <!-- <tfoot>
          <b-tr>
            <b-th colspan="6" class="text-center">
              <button @click="openModalIdeas({is_new: 'Y', is_active: 'Y'})" id="show-btn" type="button" class="btn btn-rounded btn-outline-success mt-2"><i
              class="fa fa-plus-circle mr-2"></i>Add New </button>
            </b-th>
          </b-tr>
        </tfoot> -->
      </b-table-simple>
    </b-card-body>

    <b-modal id="modal_title_ideas" title="Title" size="lg">
      <validation-observer ref="VFormTitle" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitTitle)">
          <b-form-group>
            <template #label>Title (ID) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalTitle.asc_title_id"></b-form-input>
            <VValidate name="Title (ID)" v-model="dataModalTitle.asc_title_id" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Title (EN) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalTitle.asc_title_en"></b-form-input>
            <VValidate name="Title (EN)" v-model="dataModalTitle.asc_title_en" rules="required|min:3|max:150" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_title_ideas')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitTitle">Submit</b-button>
      </template>
    </b-modal>
    <b-modal id="modal_ideas" title="Ideas Content">
      <validation-observer ref="VFormIdeas" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitIdeas)">
          <b-form-group>
            <template #label>Title (ID) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalIdeas.title_id"></b-form-input>
            <VValidate name="Title (ID)" v-model="dataModalIdeas.title_id" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Title (EN) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalIdeas.title_en"></b-form-input>
            <VValidate name="Title (EN)" v-model="dataModalIdeas.title_en" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Image <span class="text-danger">*</span></template>
            <Uploader v-model="dataModalIdeas.image" type="home_ideas" />
            <VValidate name="Image" v-model="dataModalIdeas.image" rules="required" />
          </b-form-group>
          <b-form-group>
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="parent.Config.mr.StatusOptions"
                v-model="dataModalIdeas.is_active"
              />
              <VValidate 
                name="Status" 
                v-model="dataModalIdeas.is_active" 
                rules="required" 
              />
            </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_ideas')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitIdeas">Submit</b-button>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
const _ = global._
import Gen from '@/libs/Gen.js'

export default {
  data(){
    return {
      ideas: {},
      dataModalTitle: {},
      dataModalIdeas: {},
      showIdeas: '',
    }
  },
  mounted(){
    this.apiGet()
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    apiGet(){
      Gen.apiRest('/get/BoHome/ideas', {}, 'GET').then(res => {
        this.$set(this, 'ideas', res.data.ideas)
        this.$set(this, 'showIdeas', res.data.showIdeas)
      })
    },
    openModalTitle(){
      this.$set(this, 'dataModalTitle', _.clone({
        asc_title_id: this.ideas.asc_title_id,
        asc_title_en: this.ideas.asc_title_en, 
        asc_sub_title_id: this.ideas.asc_sub_title_id, 
        asc_sub_title_en: this.ideas.asc_sub_title_en
      }))
      this.$bvModal.show('modal_title_ideas')
    },
    submitTitle(){
      this.$refs.VFormTitle.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoHome", {
          data: {
            type: 'submit-ideas-title',
            ...this.dataModalTitle  
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.ideas, 'asc_title_id', res.data.data.asc_title_id)
          this.$set(this.ideas, 'asc_title_en', res.data.data.asc_title_en)
          this.$set(this.ideas, 'asc_sub_title_id', res.data.data.asc_sub_title_id)
          this.$set(this.ideas, 'asc_sub_title_en', res.data.data.asc_sub_title_en)
          this.$bvModal.hide('modal_title_ideas')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    openModalIdeas(value){
      this.$set(this, 'dataModalIdeas', _.clone(value))
      this.$bvModal.show('modal_ideas')
    },
    submitIdeas(){
      this.$refs.VFormIdeas.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest("/do/BoHome", {
          data: {
            type: 'submit-ideas',
            ...this.dataModalIdeas
          }
        }, "POST").then(res => {
          this.$set(this.ideas, 'asc_data', res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.$bvModal.hide('modal_ideas')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },
    doDelete(id){
      this.parent.swalDelete()
      .then(result => {
        if(result.value){
          this.parent.swalLoading()

          Gen.apiRest("/do/BoHome", {
            data: {
              type: 'delete-ideas',
              id: id,
            }
          }).then(res => {
            this.$set(this.ideas, 'asc_data', res.data.data)
            this.$swal({
              icon: 'success',
              title: res.data.message
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              text: err.response.data.message || 'Something went wrong!'
            })
          })
        }
      })
    }
  }
}
</script>