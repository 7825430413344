<template>
  <b-card>
    <b-card-header>
      <b-card-title title-tag="h5">Achievement</b-card-title>
    </b-card-header>
    <b-card-body>
      <div class="d-flex align-items-center mb-2">
        <b-form-checkbox class v-model="showAchievement" @change="$emit('doChangeShowContent', 'show-achievement-home', $event)" value="Y" unchecked-value="N" name="showAbout">Show Section?</b-form-checkbox>
        <button @click="openModalTitle" type="button" class="btn btn-rounded ml-auto btn-outline-info" variant="secondary" size="sm" pill>
          Update Content <i class="fas fa-pencil-alt ml-2"></i>
        </button>
      </div>
      <div class="wrap_title_desc">
        <h4>{{ achievement.asc_title_id }}</h4>
        <p>{{ achievement.asc_sub_title_id }}</p>        
      </div>
      <b-row>
        <b-col lg="3" v-for="(value, index) in achievement.asc_data" :key="index">
          <div class="achievement_wrap_list">
            <h2 :style="'color: ' + value.color ">{{value.value}}</h2>
            <p class="mb-2">{{value.title_id}}</p>
            <span v-if="value.is_active == 'Y'" class="badge badge-success">Active</span>
            <span v-else class="badge badge-danger">Inactive</span>

            <div class="bullet-cta">
              <b-button @click="openModalValue(value)" variant="secondary" size="sm" pill class="btn-icon" v-b-tooltip.hover="'Update Content'">
                <i class="fas fa-pencil-alt" />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>

    <b-modal id="modal_title_achievement" title="Title" size="lg">
      <validation-observer ref="VFormTitle" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitTitle)">
          <b-form-group>
            <template #label>Title (ID) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalTitle.asc_title_id"></b-form-input>
            <VValidate name="Title (ID)" v-model="dataModalTitle.asc_title_id" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Sub Title (ID) </template>
            <b-form-textarea v-model="dataModalTitle.asc_sub_title_id"></b-form-textarea>
            <VValidate name="Sub Title (ID)" v-model="dataModalTitle.asc_sub_title_id" rules="min:3" />
          </b-form-group>
          <b-form-group>
            <template #label>Title (EN) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalTitle.asc_title_en"></b-form-input>
            <VValidate name="Title (EN)" v-model="dataModalTitle.asc_title_en" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Sub Title (EN)</template>
            <b-form-textarea v-model="dataModalTitle.asc_sub_title_en"></b-form-textarea>
            <VValidate name="Sub Title (EN)" v-model="dataModalTitle.asc_sub_title_en" rules="min:3" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_title_achievement')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitTitle">Submit</b-button>
      </template>
    </b-modal>

    <b-modal id="modal_value" title="Value Content">
      <validation-observer ref="VFormValue" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitValue)">
          <b-form-group>
            <template #label>Title (ID) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalValue.title_id"></b-form-input>
            <VValidate name="Title (ID)" v-model="dataModalValue.title_id" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Title (EN) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalValue.title_en"></b-form-input>
            <VValidate name="Title (EN)" v-model="dataModalValue.title_en" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Value <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalValue.value"></b-form-input>
            <VValidate name="Value" v-model="dataModalValue.value" rules="required|min:3|max:30" />
          </b-form-group>
          <b-form-group>
            <template #label>Color <span class="text-danger">*</span></template>
            <chrome-picker :value="dataModalValue.color" @input="updateColor($event, dataModalValue, 'color')" />
            <VValidate name="Color" v-model="dataModalValue.color" rules="required" />
          </b-form-group>
          <b-form-group>
            <label>Status<span class="text-danger mr5">*</span></label>
            <b-form-radio-group
              :options="parent.Config.mr.StatusOptions"
              v-model="dataModalValue.is_active"
            />
            <VValidate 
              name="Status" 
              v-model="dataModalValue.is_active" 
              rules="required" 
            />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_value')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitValue">Submit</b-button>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
const _ = global._
import Gen from '@/libs/Gen.js'

import { Chrome } from 'vue-color'


export default {
  components: {
    'chrome-picker': Chrome
  },
  data(){
    return {
      achievement: {},
      dataModalTitle: {},
      dataModalValue: {},
      showAchievement: '',
    }
  },
  mounted(){
    this.apiGet()
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    updateColor(colorValue, data, key){
      this.$set(data, key, colorValue.hex8)
    },
    apiGet(){
      Gen.apiRest('/get/BoHome/achievement', {}, 'GET').then(res => {
        this.$set(this, 'achievement', res.data.achievement)
        this.$set(this, 'showAchievement', res.data.showAchievement)
      })
    },
    openModalTitle(){
      this.$set(this, 'dataModalTitle', _.clone({
        asc_title_id: this.achievement.asc_title_id, 
        asc_title_en: this.achievement.asc_title_en,
        asc_sub_title_id: this.achievement.asc_sub_title_id, 
        asc_sub_title_en: this.achievement.asc_sub_title_en
      }))
      this.$bvModal.show('modal_title_achievement')
    },
    submitTitle(){
      this.$refs.VFormTitle.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoHome", {
          data: {
            type: 'submit-achievement-title',
            ...this.dataModalTitle  
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.achievement, 'asc_title_id', res.data.data.asc_title_id)
          this.$set(this.achievement, 'asc_title_en', res.data.data.asc_title_en)
          this.$set(this.achievement, 'asc_sub_title_id', res.data.data.asc_sub_title_id)
          this.$set(this.achievement, 'asc_sub_title_en', res.data.data.asc_sub_title_en)
          this.$bvModal.hide('modal_title_achievement')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    openModalValue(value){
      this.$set(this, 'dataModalValue', _.clone({...value, color: value.color || '#FFFFFF'}))
      this.$bvModal.show('modal_value')
    },
    submitValue(){
      this.$refs.VFormValue.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest("/do/BoHome", {
          data: {
            type: 'submit-value',
            ...this.dataModalValue
          }
        }, "POST").then(res => {
          this.$set(this.achievement, 'asc_data', res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.$bvModal.hide('modal_value')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },
    doDelete(id){
      this.parent.swalDelete()
      .then(result => {
        if(result.value){
          this.parent.swalLoading()

          Gen.apiRest("/do/BoHome", {
            data: {
              type: 'delete-ideas',
              id: id,
            }
          }).then(res => {
            this.$set(this.achievement, 'asc_data', res.data.data)
            this.$swal({
              icon: 'success',
              title: res.data.message
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              text: err.response.data.message || 'Something went wrong!'
            })
          })
        }
      })
    }
  }
}
</script>