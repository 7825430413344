<template>
  <bo-card title="Slideshow" :use-table="false">
    <b-card-body class="pt-0">
      <h6>Main Video</h6>
      <b-row>
        <b-col md="4">
          <div class="video-card position-relative overflow-hidden">
            <b-img :src="parent.uploader(sliderData.ahi_image)" />
            <a href="javascript:;" class="vc-overlay text-white" @click="openModalSlider" v-b-tooltip.hover.noninteractive="'Edit Video'">
              <i class="far fa-play-circle"></i>
            </a>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body class="border-top">
      <h6 class="card-title">Content Carousel</h6>
      <div class="d-flex align-items-center">
        <b-form-group class="mb-0 mr-3">
          <b-form-checkbox
            id="autoplayCarousel"
            v-model="autoplay.autoplay"
            name="autoplayCarousel"
            value="Y"
            unchecked-value="N"
            @change="changeAutoplay"
          >Enable Content Carousel Autoplay?</b-form-checkbox>
        </b-form-group>
        <b-form-group class="mb-0" label-cols="auto" content-cols-md="4" label="Autoplay Delay"
          label-for="autoplayDelay">
          <b-input-group append="S">
            <b-form-input v-model="autoplay.delay" @input="changeAutoplay" min="0" id="autoplayDelay" type="number" />
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table-simple bordered>
      <b-thead>
        <b-tr>
          <b-th>#</b-th>
          <b-th>Title & Description</b-th>
          <b-th>Button</b-th>
          <b-th>Status</b-th>
          <b-th>Action</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(value, index) in carouselData" :key="index">
          <b-td>{{index + 1}}</b-td>
          <b-td>
            <div class="detail-data">
              <strong>{{value.asc_title_id}}</strong>
              <p>{{ value.asc_content_id }}</p>
            </div>
          </b-td>
          <b-td>
            <div class="d-flex flex-column" v-if="value.asc_has_button == 'Y'">
              <span>{{ value.asc_button_text_en }}</span>
              <small><a :href="value.asc_button_link" target="_blank" class="text-info text-underline">{{ value.asc_button_link }}</a></small>
            </div>
            <template v-else>
              <b-badge variant="danger">Not Showing</b-badge>
            </template>
          </b-td>
          <b-td>
            <b-badge v-if="value.asc_is_active == 'Y'" variant="success">Active</b-badge>
            <b-badge v-else variant="danger">Inactive</b-badge>
          </b-td>
          <b-td>
            <b-button class="btn-icon rounded-pill" variant="secondary" @click="openModalCarousel(value)">
              <i class="fas fa-pencil-alt"></i>
            </b-button>
            <b-button class="btn-icon rounded-pill" @click="doDeleteCarousel(value.asc_id)" variant="danger"><i class="far fa-trash-alt"></i></b-button>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td colspan="5" class="text-center">
            <b-button type="button" class="btn-rounded" variant="outline-success" @click="openModalCarousel({asc_is_active: 'Y'})">
              <i class="fa fa-plus-circle mr-2"></i>Add New
            </b-button>
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>

    <b-card class="border-top">
      <h6 class="card-title">Client Order Configuration</h6>
      <b-row>
        <b-col md="4">
          <v-select :options="clientsOrderConfig" v-model="order.order" :reduce="v => v.value" label="label" placeholder="-- Select Order --" @input="changeOrderClient()" :disabled="loading.changeOrder" />
        </b-col>

        <b-col md="12" class="mt-3">
          <b-row>
            <template v-if="order.order == 'RAND'">
              <b-col md="12">
                <draggable :list="clients" class="row" @change="changeOrderClient">
                  <b-col md="2" v-for="(v, k) in clients" :key="`client-${k}`" class="cursor-move">
                    <b-img :src="parent.uploader(v.logo)" />
                    <p class="text-center mb-0">{{ v.name }}</p>
                  </b-col>            
                </draggable>
              </b-col>
            </template>

             <template v-else>
              <b-col md="2" v-for="(v, k) in clients" :key="`client-${k}`">
                <b-img :src="parent.uploader(v.logo)" />
                <p class="text-center mb-0">{{ v.name }}</p>
              </b-col>
             </template>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-modal id="modal_slider" title="Update Video" centered no-close-on-backdrop>
      <validation-observer ref="VFormSlider" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitSlider)">
          <b-form-group>
            <template #label>Hero Video <span class="text-danger">*</span> </template>
            <Uploader key="upload_video" v-model="dataModalSlider.ahi_video" :isVideo="true" type="slider_video"/>
            <VValidate 
              name="Hero Video" 
              v-model="dataModalSlider.ahi_video"
              rules="required" 
            />
          </b-form-group>
          <b-form-group>
            <template #label>Video Poster <span class="text-danger">*</span> </template>
            <Uploader v-model="dataModalSlider.ahi_image" type="poster_video"/>
            <VValidate 
              name="Video Poster" 
              v-model="dataModalSlider.ahi_image"
              rules="required" 
            />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="closeModal('modal_slider')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitSlider">Submit</b-button>
      </template>
    </b-modal>

    <b-modal id="modal_carousel" title="Update Content Carousel" centered no-close-on-backdrop size="lg">
      <validation-observer ref="VFormCarousel" v-slot="{handleSubmit}">
        <b-form @submit="handleSubmit(submitCarousel)">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template #label>Title (ID) <span class="text-danger">*</span></template>
                <b-form-input v-model="dataModalCarousel.asc_title_id" placeholder="e.g. Tingkatkan Bisnis Anda dengan Memanfaatkan Teknologi Mutakhir"></b-form-input>
                <VValidate name="Title (ID)" v-model="dataModalCarousel.asc_title_id" rules="required|min:3|max:160" />
              </b-form-group>
              <b-form-group>
                <template #label>Title (EN) <span class="text-danger">*</span></template>
                <b-form-input v-model="dataModalCarousel.asc_title_en" placeholder="e.g. Scale-up Your Business by Leveraging Cutting-edge Technologies"></b-form-input>
                <VValidate name="Title (EN)" v-model="dataModalCarousel.asc_title_en" rules="required|min:3|max:160" />
              </b-form-group>
              <b-form-group>
                <template #label>Content (ID) <span class="text-danger">*</span></template>
                <b-form-textarea v-model="dataModalCarousel.asc_content_id" placeholder="e.g. Berfokus pada layanan Solusi TI khususnya..."></b-form-textarea>
                <VValidate name="Content (ID)" v-model="dataModalCarousel.asc_content_id" rules="required|min:3" />
              </b-form-group>
              <b-form-group>
                <template #label>Content (EN) <span class="text-danger">*</span></template>
                <b-form-textarea v-model="dataModalCarousel.asc_content_en" placeholder="e.g. Focusing on IT Solutions services especially..."></b-form-textarea>
                <VValidate name="Content (EN)" v-model="dataModalCarousel.asc_content_en" rules="required|min:3" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group class="mt-3">
                <template #label>Status <span class="text-danger">*</span></template>
                <b-form-radio-group
                  :options="parent.Config.mr.StatusOptions"
                  v-model="dataModalCarousel.asc_is_active"
                />
                <VValidate 
                  name="Status" 
                  v-model="dataModalCarousel.asc_is_active" 
                  :rules="{required:1}" 
                />
              </b-form-group>
              <b-form-group>
                <b-form-checkbox v-model="dataModalCarousel.asc_has_button" name="showButtonCarousel" value="Y" unchecked-value="N">Show Button?</b-form-checkbox>
              </b-form-group>
              <template v-if="dataModalCarousel.asc_has_button == 'Y'">
                <b-form-group>
                  <template #label>Button Text (ID) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalCarousel.asc_button_text_id" placeholder="e.g. View Our Works"></b-form-input>
                  <VValidate name="Button Text (ID)" v-model="dataModalCarousel.asc_button_text_id" rules="required|min:3|max:60" />
                </b-form-group>
                <b-form-group>
                  <template #label>Button Text (EN) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalCarousel.asc_button_text_en" placeholder="e.g. View Our Works"></b-form-input>
                  <VValidate name="Button Text (EN)" v-model="dataModalCarousel.asc_button_text_en" rules="required|min:3|max:60" />
                </b-form-group>
                <b-form-group>
                  <template #label>Button Link <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalCarousel.asc_button_link" placeholder="e.g. https://lingkar9.com"></b-form-input>
                  <VValidate name="Button Link" v-model="dataModalCarousel.asc_button_link" rules="required|min:3|max:250" />
                </b-form-group>
              </template>
            </b-col>
          </b-row>
        </b-form>        
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="closeModal('modal_carousel')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitCarousel">Submit</b-button>
      </template>
    </b-modal>


  </bo-card>
</template>
<script>
import BoCard from '@/components/backend/BoCard.vue'
import Gen from '@/libs/Gen.js'
import draggable from 'vuedraggable'

// let _ = global._

export default {
  props: {
    modulePage: String
  },
  components: {
    BoCard,
    draggable
  },
  data(){
    return {
      sliderData: {},
      carouselData: [],
      autoplay: {},
      slideRow: {},
      dataModalSlider: {},
      dataModalCarousel: {},

      loading: {
        changeOrder: false
      },
      order: {},
      clients: [],
      clientsOrderConfig: []
    }
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {

    changeAutoplay: _.debounce(function() {
      if(this.autoplay.delay < 0) return

      Gen.apiRest("/do/BoHome", {
        data: {
          type: 'submit-autoplay',
          autoplay: this.autoplay
        }
      }).then(res => {
        this.$swal({
          icon: 'success',
          title: res.data.message
        })
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    }, 500),
    closeModal(id){
      this.$bvModal.hide(id)
    },
    openModalSlider(){
      this.$set(this, 'dataModalSlider', _.clone({ahi_image: this.sliderData.ahi_image, ahi_video: this.sliderData.ahi_video}))
      this.$bvModal.show('modal_slider')
    },
    submitSlider(){
      this.$refs.VFormSlider.validate().then(result => {
        if(!result) return 

        this.parent.swalLoading()
        Gen.apiRest("/do/BoHome", {
          data: {
            type: 'submit-slider',
            ...this.dataModalSlider
          }
        }, "POST").then(res => {
          this.$set(this, 'sliderData', res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$bvModal.hide('modal_slider')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })


      })

    },
    openModalCarousel(value){
      this.$set(this, 'dataModalCarousel', _.clone(value))
      this.$bvModal.show('modal_carousel')
    },
    submitCarousel(){
      this.$refs.VFormCarousel.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoHome", {
          data: {
            type: 'submit-carousel',
            ...this.dataModalCarousel
          }
        }).then(res => {
          this.$set(this, 'carouselData', res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$bvModal.hide('modal_carousel')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message  || "Something went wrong"
          })
        })
      })
    },
    apiGet(){
      Gen.apiRest('/get/BoHome/slider', {}, 'GET').then(res => {
        this.sliderData = res.data.slider
        this.mrValidation = res.data.validation
        this.autoplay = res.data.autoplay
        this.carouselData = res.data.carousel
        this.clients = res.data.clients
        this.clientsOrderConfig = res.data.clientsOrderConfig
        this.order = res.data.order
      })
    },
    doDeleteCarousel(id){
      this.parent.swalDelete()
      .then(result => {
        if(result.value){
          this.parent.swalLoading()

          Gen.apiRest("/do/BoHome", {
            data: {
              type: 'delete-carousel',
              id: id
            }
          }).then(res => {
            this.$set(this, 'carouselData', res.data.data)
            this.$swal({
              icon: 'success',
              title: res.data.message,
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              text: err.response.data.message || "Something went wrong!"
            })
          })
        }
      })
    },
    changeOrderClient: _.debounce(function() {
      console.log(this.clients)
      this.$set(this.loading, 'changeOrder', true)

      Gen.apiRest("/do/BoHome", {
        data: {
          type: 'change-order-client',
          order: this.order.order,
          data: this.clients||[]
        }
      }).then(res => {
        this.$swal({
          icon: 'success',
          title: res.data.message,
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 1200,
          timerProgressBar: true,
        })
        this.$set(this.loading, 'changeOrder', false)
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    }, 500)
  },
}
</script>
