<template>
  <b-card>
    <b-card-header>
      <b-card-title title-tag="h5">About Lingkar9 and Intro</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-form-checkbox class v-model="showAboutL9" @change="$emit('doChangeShowContent', 'show-about-home', $event)" value="Y" unchecked-value="N" name="showAbout">Show Section?</b-form-checkbox>
      <b-row class="mt-2">
        <b-col md="6">
          <div class="glance_l9">
            <div v-html="about.glance.asc_content_id"></div>
            <div class="bullet-cta">
              <b-button variant="secondary" size="sm" pill @click="openModalAboutGlance" class="btn-icon" v-b-tooltip.hover.noninteractive="'At a Glance'">
                <i class="fas fa-pencil-alt" />
              </b-button>
            </div>
          </div>

          <!-- MODAL At A Glance  -->
          <b-modal id="modal_glance" title="At a glance lingkar9" size="lg">
            <validation-observer ref="VFormGlance" v-slot="{handleSubmit}">
              <b-form @submit.prevent="handleSubmit(submitGlance)">
                <b-row>
                  <b-col lg="12">
                    <b-form-group label-for="articleContent">
                      <template #label>Description (ID)<span class="text-danger mr5">*</span></template>
                      <QuillEditor 
                        id="articleContentID"
                        height="250px"
                        :value.sync="dataModalGlance.asc_content_id"
                        :customToolbar="itemsToolbar"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="12">
                    <b-form-group>
                      <template #label>Description (EN)<span class="text-danger mr5">*</span></template>
                      <QuillEditor 
                        id="articleContentEN"
                        height="250px"
                        :value.sync="dataModalGlance.asc_content_en"
                        :customToolbar="itemsToolbar"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <template #modal-footer>
              <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_glance')">Cancel</b-button>
              <b-button type="button" variant="primary" @click="submitGlance">Submit</b-button>
            </template>
          </b-modal>
          <!-- END MODAL-->
        </b-col>
        <b-col md="6" class="mt-2">
          <div class="glance_l9">            
            <h2 style="">{{about.different.asc_title_id}}</h2>
            <h2 :style="'color:' + about.different.color">{{about.different.asc_sub_title_id}}</h2>
            <!-- <div v-html="about.different.asc_content_id"></div> -->

            <div ref="swiper" class="swiper">
              <div class="swiper-wrapper">
                <!-- Slides -->
                <div class="swiper-slide" v-for="(v, k) in testimonials" :key="`testi-${k}`">
                  <p>{{ v.description_id }}</p>

                  <p>— {{ v.name_content }}</p>
                </div>
              </div>              
            </div>

            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>

            <div class="bullet-cta">
              <!-- @click="openModalAboutDifferent" -->
              <router-link :to="{ name: 'BoTestimonials' }">
                <b-button variant="secondary" size="sm" pill  class="btn-icon" v-b-tooltip.hover="'Update Content'">
                  <i class="fas fa-pencil-alt" />
                </b-button>
              </router-link>
            </div>
          </div>
          <!-- MODAL At A Glance  -->
          <b-modal id="modal_different" title="They Are Different" size="lg">
            <validation-observer ref="VFormDifferent" v-slot="{handleSubmit}">
              <b-form @submit.prevent="handleSubmit(submitDifferent)">
                <b-row>
                  <b-col md="6" class="mb-2">
                    <b-form-group>
                      <template #label>Heading (ID) <span class="text-danger">*</span></template>
                      <b-form-input v-model="dataModalDifferent.asc_title_id" placeholder="Lingkar 9"></b-form-input>
                      <VValidate name="Heading (ID)" v-model="dataModalDifferent.asc_title_id" rules="required|min:3|max:150" />
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="mb-2">
                    <b-form-group>
                      <template #label>Heading (EN) <span class="text-danger">*</span></template>
                      <b-form-input v-model="dataModalDifferent.asc_title_en" placeholder="Lingkar 9"></b-form-input>
                      <VValidate name="Heading (EN)" v-model="dataModalDifferent.asc_title_en" rules="required|min:3|max:150" />
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="mb-2">
                    <b-form-group>
                      <template #label>Sub Heading (ID)</template>
                      <b-form-input v-model="dataModalDifferent.asc_sub_title_id" placeholder="They are Different" ></b-form-input>
                      <VValidate name="Sub Heading (ID)" v-model="dataModalDifferent.asc_sub_title_id" rules="required|min:3|max:150" />
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="mb-2">
                    <b-form-group>
                      <template #label>Sub Heading (EN)</template>
                      <b-form-input v-model="dataModalDifferent.asc_sub_title_en" placeholder="They are Different" ></b-form-input>
                      <VValidate name="Sub Heading (EN)" v-model="dataModalDifferent.asc_sub_title_en" rules="required|min:3|max:150" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <template #label>Color <span class="text-danger">*</span></template>
                      <chrome-picker :value="dataModalDifferent.color" @input="updateColor($event, dataModalDifferent, 'color')" />
                      <VValidate name="Color" v-model="dataModalDifferent.color" rules="required" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="12">
                    <b-form-group label-for="articleContent">
                      <template #label>Description (ID) <span class="text-danger">*</span></template>
                      <QuillEditor 
                        id="differentID"
                        height="250px"
                        :value.sync="dataModalDifferent.asc_content_id"
                        :customToolbar="itemsToolbar"
                      />
                      <VValidate name="Description (ID)" v-model="dataModalDifferent.asc_content_id" rules="required" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="12">
                    <b-form-group label-for="articleContent">
                      <template #label>Description (EN) <span class="text-danger">*</span></template>
                      <QuillEditor 
                        id="differentEN"
                        height="250px"
                        :value.sync="dataModalDifferent.asc_content_en"
                        :customToolbar="itemsToolbar"
                      />
                      <VValidate name="Description (EN)" v-model="dataModalDifferent.asc_content_en" rules="required" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <template #modal-footer>
              <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_different')">Cancel</b-button>
              <b-button type="button" variant="primary" @click="submitDifferent">Submit</b-button>
            </template>
          </b-modal>
          <!-- END MODAL-->
        </b-col>
      </b-row>
    </b-card-body>

  </b-card>
</template>
<script>
const _ = global._

import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/swiper-bundle.css'

import QuillEditor from '@/components/QuillEditor'
import Gen from '@/libs/Gen.js'

import { Chrome } from 'vue-color'
export default {
  components: {
    QuillEditor,
    'chrome-picker': Chrome,
  },
  data(){
    return {
      itemsToolbar: [
        'heading',
        '|',
        'sourceEditing',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        '|', 
        'alignment',
        '|', 
        'outdent', 'indent',
      ],
      about: {
        glance: {},
        different: {}
      },

      testimonials: [],
      dataModalGlance: {},
      dataModalDifferent: {},
      showAboutL9: '',
    }
  },
  mounted(){
    this.apiGet()

    new Swiper(this.$refs.swiper, {
      modules: [Navigation, Pagination],
      loop: true,

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    updateColor(colorValue, data, key){
      this.$set(data, key, colorValue.hex8)
    },
    apiGet(){
      Gen.apiRest('/get/BoHome/about', {}, 'GET').then(res => {
        this.$set(this, 'about', res.data.about)
        this.$set(this, 'testimonials', res.data.testimonials)
        this.$set(this, 'showAboutL9', res.data.showAboutL9)
      })
    },
    openModalAboutGlance(){
      this.$set(this, 'dataModalGlance', _.clone(this.about.glance))
      this.$bvModal.show('modal_glance')
    },
    openModalAboutDifferent(){
      this.$set(this, 'dataModalDifferent', _.clone(this.about.different))
      this.$bvModal.show('modal_different')
    },
    submitGlance(){
      this.$refs.VFormGlance.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoHome", {
          data: {
            type: 'submit-about-glance',
            ...this.dataModalGlance  
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.about, 'glance', res.data.data)
          this.$bvModal.hide('modal_glance')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    submitDifferent(){
      this.$refs.VFormDifferent.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoHome", {
          data: {
            type: 'submit-about-different',
            ...this.dataModalDifferent  
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.about, 'different', res.data.data)
          this.$bvModal.hide('modal_different')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
  }
}
</script>

<style scoped>

.swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 400px);
  right: auto;
  top: 21em;
}

.swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
  top: 21em;
}

.swiper-slide {
  cursor: grab;
}

</style>